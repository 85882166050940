import React from 'react';
import './App.scss';
import MainView from './MainView';

function App() {
  return (
    <div id="App">
      <MainView className="MainView" />
    </div>
  )
}

export default App;

import * as Icons from '@fortawesome/free-solid-svg-icons';

const roles = {
	dev: { name: "dev", icon: Icons.faTerminal },
	designer: { name: "designer", icon: Icons.faPalette }
}

const members = [
	{ "id": 1, "name": "Slawa", "role": roles.dev },
	{ "id": 2, "name": "Rok", "role": roles.dev },
	{ "id": 3, "name": "Ludovico", "role": roles.dev },
	{ "id": 4, "name": "Oliver", "role": roles.dev },
	{ "id": 5, "name": "Robert", "role": roles.dev },
	{ "id": 6, "name": "Tobi", "role": roles.dev },
	{ "id": 7, "name": "Benni", "role": roles.designer },
	{ "id": 8, "name": "Yelena", "role": roles.designer },
];

const settings = {
	animationScale: 1,
}

export default members;
export { settings }